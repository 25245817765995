export const FREE_PLAN_WIDGETS = 1;
export const APP_NAMES = {
  duplicate_page: "Recurring Tasks",
  eth_nft_tracker: "ETH NFT Wallet Tracker",
  pomodoro: "Pomodoro Timer",
};

export const APP_URLS = {
  duplicate_page: "/app/recurring-tasks",
  eth_nft_tracker: "/app/nft-wallet-tracker",
  pomodoro: "/app/pomodoro",
};

export const SETTINGS_TAB = {
  INTEGRATIONS: "integrations",
  PLANSSUB: "plansSub",
  PROFILE: "profile",
};

export const TYPE = {
  SUBSCRIPTION: "subscription",
  ACCOUNT: "account",
};
